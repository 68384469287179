import {
    Alert,
    Box,
    Button, CircularProgress, FilledInput,
    FormControl, FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Modal, OutlinedInput,
    Select, Snackbar,
    TextField,
    Typography
} from '@mui/material';
import React, {ElementType, useEffect, useRef, useState} from 'react';
import {EventSourceInput} from "@fullcalendar/core";
import {FieldArray, FormikErrors, FormikProvider, useFormik} from "formik";
import {DatePicker, LocalizationProvider, renderTimeViewClock, TimePicker} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {Moment} from "moment";
import instance from "../../util/api";
import moment from 'moment-timezone';
import * as Yup from 'yup';
import {ConfigType} from "../../types/ConfigType";

moment.tz.setDefault('Europe/Paris');
moment.updateLocale('en', {
    week: {
        dow: 1,
    },
});


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
};

const textFieldStyle = {
    marginBottom: 2
}

/*const places = [
    {
        value: "p1",
        label: "Place 1"
    },
    {
        value: "p2",
        label: "Place 2"
    }
]*/

export interface IEventValues {
    uuid?: string,
    mainSubject: string,
    description: string,
    details: IEventDetailValues[]
}

export interface IEventDetailValues {
    id?: string,
    startDateTime: string,
    endDateTime: string,
    contractor: string,
    start: Moment | null,
    startTime: Moment | null,
    end: Moment | null,
    endTime: Moment | null,
    place: string[],
    number_persons?: number,
    event_type?: string,
    category: string
}

interface IEventModalProps {
    event?: IEventValues,
    setCloseCb?: () => void,
    callback?: () => void,
    configs: ConfigType[]
}

const intialValues = {
    mainSubject: '',
    description: '',
    details: [
        {
            startDateTime: '',
            endDateTime: '',
            contractor: '',
            start: null as Moment | null,
            startTime: null as Moment | null,
            end: null as Moment | null,
            endTime: null as Moment | null,
            place: [] as string[],
            event_type: '',
            category: ''
        }
    ]
} as IEventValues;

const validationSchema = Yup.object({
    mainSubject: Yup.string().required('Please enter a contractor name.'),
    description: Yup.string(),
    details: Yup.array().of(
        Yup.object({
            startDateTime: Yup.string(),
            endDateTime: Yup.string(),
            contractor: Yup.string(),
            start: Yup.mixed().required('Please enter a start date.'),
            startTime: Yup.mixed().required('Please enter a start time'),
            end: Yup.mixed().required('Please enter an end date.'),
            endTime: Yup.mixed().required('Please enter a end time.'),
            place: Yup.array().min(1, 'Please select at least one location')
                .required('Please select a location'),
            number_persons: Yup.number().required('Please enter a number of persons.'),
            event_type: Yup.string(),
            category: Yup.string().required('Please enter a category.')
        })
    )
});

const EventModal : React.FC<IEventModalProps> = (props) => {
    const [open, setOpen] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const [error, setError] = useState<string | undefined>();

    const placeConfig = props.configs.find((config) => config.key === "places");
    const places = placeConfig ? placeConfig.value : [];

    const eventConfig = props.configs.find((config) => config.key === "eventTypes");
    const eventTypes = eventConfig ? eventConfig.value : [];

    const categoryConfig = props.configs.find((config) => config.key === "categories");
    const categories = categoryConfig ? categoryConfig.value : [];

    const deleteModalRef = useRef<any & {
        deleteIndex?: number,
        deleteCb?: any,
        deleteId?: string
    } | null>(null);

    const formik = useFormik({
        initialValues: intialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            values.details.map((detail) => {
                if (detail.start && detail.startTime) {
                    detail.start = detail.start.set({
                        hour: detail.startTime.hour(),
                        minute: detail.startTime.minute()
                    })
                        //.subtract(2, 'hours') // Workaround for timezone issue
                    detail.startDateTime = detail.start.format()
                }
                if (detail.end && detail.endTime) {
                    detail.end = detail.end.set({
                        hour: detail.endTime.hour(),
                        minute: detail.endTime.minute()
                    })
                        //.subtract(2, 'hours') // Workaround for timezone issue
                    detail.endDateTime = detail.end.format()
                }

                return detail;
            });

            console.log(values);

            createEditEvent(values);
        },
    });

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        if (props.setCloseCb) {
            props.setCloseCb();
        }
    }

    const handleDeleteModalClose = () => {
        setOpenDeleteModal(false);
    }

    useEffect(() => {
        if (props.event !== undefined) {

            console.log("EVENT", props.event)
            formik.setValues(props.event);

            setOpen(true)
        } else {
            formik.setValues(intialValues);
        }
    }, [props.event]);
    const createEditEvent = (event: any) => {
        setLoading(true);

        console.log("SAVE EVENT", event);
        if (props.event) {
            instance.put("/api/v1/event", event)
                .then((resp) => {
                    console.log(resp.data)

                    if (props.callback) {
                        props.callback();
                    }

                    handleClose();
                    setSuccess(true);
                })
                .catch(err => {
                    console.log(err);
                    setError("Error creating event. Please try again or try to login again.")
                })
                .finally(() => {
                    setLoading(false);
                })
        } else {
            instance.post("/api/v1/event", event)
                .then((resp) => {
                    console.log(resp.data)

                    if (props.callback) {
                        props.callback();
                    }

                    handleClose();
                    setSuccess(true);
                })
                .catch(err => {
                    console.log(err);
                    setError("Error creating event. Please try again or try to login again.")
                })
                .finally(() => {
                    setLoading(false);
                })
            /*instance.get("/api/v1/event/init")
                .then((resp) => {
                    console.log(resp.data)
                })*/
        }
    }

    const deleteEvent = (index: number, cb: any, id?: string) => {
        if (id) {
            setOpenDeleteModal(true);

            console.log(deleteModalRef.current)

            if (deleteModalRef.current) {
                deleteModalRef.current.deleteIndex = index;
                deleteModalRef.current.deleteCb = cb;
                deleteModalRef.current.deleteId = id;
            }
        } else {
            cb(index);
        }
    }

    const onDeleteEvent = () => {
        console.log(deleteModalRef.current?.deleteIndex, deleteModalRef.current?.deleteCb, deleteModalRef.current?.deleteId);
        if (deleteModalRef.current) {
            instance.delete("/api/v1/event/id/" + deleteModalRef.current.deleteId)
                .then((resp) => {
                    console.log(resp.data)

                    deleteModalRef.current.deleteCb(deleteModalRef.current.deleteIndex);
                    setOpenDeleteModal(false);

                    if (props.callback) {
                        props.callback();
                    }
                })
                .catch(err => {
                    console.log(err);
                    setError("Error deleting event. Please try again or try to login again.")
                })

        }
    }

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterMoment}  dateLibInstance={moment}>
                <Button
                    variant="contained"
                    color={"primary"}
                    onClick={handleOpen}>+ New event</Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    disableScrollLock={true}
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h4" component="h2">
                            Create new event
                        </Typography>
                        <Box id="modal-modal-description" sx={{mt: 2}}>
                            <FormikProvider value={formik}>
                                <Box component={"form"} onSubmit={formik.handleSubmit}>
                                    <TextField
                                        sx={textFieldStyle}
                                        fullWidth
                                        id="mainSubject"
                                        name="mainSubject"
                                        onChange={formik.handleChange}
                                        value={formik.values.mainSubject}
                                        label="Organizer / Subject"
                                        error={!!formik.errors.mainSubject}
                                        helperText={formik.errors.mainSubject}
                                        variant="filled" />

                                    <TextField
                                        sx={textFieldStyle}
                                        fullWidth
                                        id="description"
                                        name="description"
                                        onChange={formik.handleChange}
                                        value={formik.values.description}
                                        label="Description"
                                        multiline
                                        rows={6}
                                        error={!!formik.errors.description}
                                        helperText={formik.errors.description}
                                        variant="filled" />

                                    <FieldArray name={"details"}>
                                        {({ insert, remove, push }) => (
                                            <div>
                                                {formik.values.details.length > 0 && formik.values.details.map((detail, index) => {
                                                    const detailErrors = (formik.errors.details && formik.errors.details[index] ? formik.errors.details[index] : {}) as FormikErrors<IEventDetailValues>

                                                    return (
                                                        <div key={`init_${index}`}>
                                                            <Grid key={`container_${index}`} container spacing={2}
                                                                  sx={{marginBottom: 4}}>
                                                                <Grid item xs={12}>
                                                                    <Button
                                                                        variant="contained"
                                                                        color={"error"}
                                                                        sx={{mt: 3, mb: 2, float: 'right'}}
                                                                        onClick={() => deleteEvent(index, remove, detail.id)}
                                                                    >
                                                                        X
                                                                    </Button>
                                                                </Grid>
                                                                <Grid item xs={12} md={6}>
                                                                    <Grid container spacing={0.5}>
                                                                        <Grid item xs={6} lg={8}>
                                                                            <DatePicker
                                                                                label="Start Date"
                                                                                name={`details.${index}.start`}
                                                                                value={detail.start}
                                                                                slotProps={{
                                                                                    textField: {
                                                                                        helperText: detailErrors.start,
                                                                                        error: !!detailErrors.start
                                                                                    },
                                                                                }}
                                                                                format={"DD.MM.YYYY"}
                                                                                onChange={(date) => {
                                                                                    formik.setFieldValue(`details.${index}.start`, date)
                                                                                    if (date && !detail.end) {
                                                                                        formik.setFieldValue(`details.${index}.end`, date)
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={6} lg={4}>
                                                                            <TimePicker
                                                                                label="Start Time"
                                                                                name={`details.${index}.startTime`}
                                                                                value={detail.startTime}
                                                                                className={"timePicker"}
                                                                                slotProps={{
                                                                                    textField: {
                                                                                        helperText: detailErrors.startTime,
                                                                                        error: !!detailErrors.startTime
                                                                                    },
                                                                                }}
                                                                                format={"HH:mm"}
                                                                                ampm={false}
                                                                                onChange={(date) => {
                                                                                    formik.setFieldValue(`details.${index}.startTime`, date)
                                                                                    if (date && !detail.endTime) {
                                                                                        formik.setFieldValue(`details.${index}.endTime`, moment(date).add("2 hours"))
                                                                                    }
                                                                                }}
                                                                                minutesStep={15}
                                                                                /*viewRenderers={{
                                                                                    hours: renderTimeViewClock,
                                                                                    minutes: renderTimeViewClock,
                                                                                    seconds: renderTimeViewClock,
                                                                                }}*/
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={12} md={6}>
                                                                    <Grid container spacing={0.5}>
                                                                        <Grid item xs={6} lg={8}>
                                                                            <DatePicker
                                                                                label="End Date"
                                                                                name={`details.${index}.end`}
                                                                                value={detail.end}
                                                                                slotProps={{
                                                                                    textField: {
                                                                                        helperText: detailErrors.end,
                                                                                        error: !!detailErrors.end
                                                                                    },
                                                                                }}
                                                                                timezone="Europe/Paris"
                                                                                format={"DD.MM.YYYY"}
                                                                                onChange={(date) => formik.setFieldValue(`details.${index}.end`, date)}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={6} lg={4}>
                                                                            <TimePicker
                                                                                label="End Time"
                                                                                className={"timePicker"}
                                                                                name={`details.${index}.endTime`}
                                                                                value={detail.endTime}
                                                                                slotProps={{
                                                                                    textField: {
                                                                                        helperText: detailErrors.endTime,
                                                                                        error: !!detailErrors.endTime
                                                                                    },
                                                                                }}
                                                                                timezone="Europe/Paris"
                                                                                format={"HH:mm"}
                                                                                ampm={false}
                                                                                onChange={(date) => formik.setFieldValue(`details.${index}.endTime`, date)}
                                                                                minutesStep={15}
                                                                                /*viewRenderers={{
                                                                                    hours: renderTimeViewClock,
                                                                                    minutes: renderTimeViewClock,
                                                                                    seconds: renderTimeViewClock,
                                                                                }}*/
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid container spacing={2} sx={{marginBottom: 4}}>
                                                                <Grid item xs={12} md={6}>
                                                                    <FormControl
                                                                        variant="filled"
                                                                        error={!!detailErrors.place}
                                                                        fullWidth>
                                                                        <InputLabel id="placeLabel">Place</InputLabel>
                                                                        <Select
                                                                            labelId="placeLabel"
                                                                            id="place"
                                                                            multiple
                                                                            name={`details.${index}.place`}
                                                                            onChange={formik.handleChange}
                                                                            value={detail.place}
                                                                        >
                                                                            {places.map((option) => (
                                                                                <MenuItem key={option.value}
                                                                                          value={option.value}>
                                                                                    {option.label}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                        <FormHelperText>{detailErrors.place}</FormHelperText>
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={6} md={3}>
                                                                    <TextField
                                                                        fullWidth
                                                                        type="number"
                                                                        id="number_persons"
                                                                        name={`details.${index}.number_persons`}
                                                                        onChange={formik.handleChange}
                                                                        value={detail.number_persons}
                                                                        error={!!detailErrors.number_persons}
                                                                        helperText={detailErrors.number_persons}
                                                                        label="Number of persons"
                                                                        variant="filled"/>
                                                                </Grid>
                                                                <Grid item xs={6} md={3}>
                                                                    {/*<TextField
                                                                        fullWidth
                                                                        id="event_type"
                                                                        name={`details.${index}.event_type`}
                                                                        onChange={formik.handleChange}
                                                                        value={detail.event_type}
                                                                        label="Event type"
                                                                        error={!!detailErrors.event_type}
                                                                        helperText={detailErrors.event_type}
                                                                        variant="filled"/>*/}

                                                                    <FormControl
                                                                        variant="filled"
                                                                        error={!!detailErrors.event_type}
                                                                        fullWidth>
                                                                        <InputLabel id="eventTypeLabel">Event type</InputLabel>
                                                                        <Select
                                                                            labelId="eventTypeLabel"
                                                                            id="event_type"
                                                                            name={`details.${index}.event_type`}
                                                                            onChange={formik.handleChange}
                                                                            value={detail.event_type}
                                                                        >
                                                                            <MenuItem key={"emptyEventType"}
                                                                                      value={""}>
                                                                                {"-- Select Event Type --"}
                                                                            </MenuItem>
                                                                            {eventTypes.map((option) => (
                                                                                <MenuItem key={option.value}
                                                                                          value={option.value}>
                                                                                    {option.label}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                        <FormHelperText>{detailErrors.event_type}</FormHelperText>
                                                                    </FormControl>
                                                                </Grid>
                                                                {/*<Grid item xs={12} md={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        id="contractor"
                                                                        name={`details.${index}.contractor`}
                                                                        onChange={formik.handleChange}
                                                                        value={detail.contractor}
                                                                        error={!!detailErrors.contractor}
                                                                        helperText={detailErrors.contractor}
                                                                        label="Contractor"
                                                                        variant="filled"/>
                                                                </Grid>*/}
                                                                <Grid item xs={12} md={6}>
                                                                    {/*<TextField
                                                                        fullWidth
                                                                        id="category"
                                                                        name={`details.${index}.category`}
                                                                        onChange={formik.handleChange}
                                                                        value={detail.category}
                                                                        error={!!detailErrors.category}
                                                                        helperText={detailErrors.category}
                                                                        label="Category"
                                                                        variant="filled"/>*/}

                                                                    <FormControl
                                                                        variant="filled"
                                                                        error={!!detailErrors.category}
                                                                        fullWidth>
                                                                        <InputLabel id="categoryLabel">Category</InputLabel>
                                                                        <Select
                                                                            labelId="categoryLabel"
                                                                            id="category"
                                                                            name={`details.${index}.category`}
                                                                            onChange={formik.handleChange}
                                                                            value={detail.category}
                                                                        >
                                                                            {categories.map((option) => (
                                                                                <MenuItem key={option.value}
                                                                                          value={option.value}>
                                                                                    {option.label}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                        <FormHelperText>{detailErrors.category}</FormHelperText>
                                                                    </FormControl>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    )
                                                })}

                                                <Button
                                                    variant="contained"
                                                    color={"secondary"}
                                                    sx={{ mt: 3, mb: 2, float: 'right'}}
                                                    onClick={() => {
                                                        const lastElement = formik.values.details[formik.values.details.length - 1]

                                                        const data = {
                                                            ...intialValues.details[0],
                                                            start: lastElement.start,
                                                            end: lastElement.end,
                                                            place: lastElement.place,
                                                            event_type: lastElement.event_type
                                                        }


                                                        push(data)
                                                    }}
                                                >
                                                    Add Agenda Item
                                                </Button>

                                            </div>
                                        )}
                                    </FieldArray>
                                    <Grid container spacing={2} sx={{marginBottom: 4}}>
                                        <Grid item xs={12}>
                                            {error && <Typography color="error">{error}</Typography>}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color={"primary"}
                                                sx={{ mt: 3, mb: 2, float: 'right'}}
                                                disabled={loading}
                                            >
                                                {props.event ? "Save Event" : "Create Event"}
                                                <CircularProgress
                                                    sx={{display: loading ? 'inline-block' : 'none', ml: 2}}
                                                    size={20}
                                                />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </FormikProvider>
                        </Box>
                    </Box>
                </Modal>
                <div
                    ref={deleteModalRef}
                >
                    <Modal
                        open={openDeleteModal}
                        onClose={handleDeleteModalClose}
                        aria-labelledby="modal-delete-title"
                        aria-describedby="modal-delete-description"
                    >
                        <Box sx={style}>
                            <Typography id="modal-delete-title" variant="h4" component="h2">
                                Delete?
                            </Typography>
                            <Typography id="modal-delete-description" sx={{ mt: 2 }}>
                                Do you really want to delete this category? It will automatically removed from all calendars.
                            </Typography>
                            <Button
                                variant="contained"
                                color={"error"}
                                sx={{ mt: 3, mb: 2}}
                                onClick={onDeleteEvent}
                            >
                                Delete
                            </Button>
                        </Box>
                    </Modal>
                </div>
            </LocalizationProvider>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={success}
                onClose={() => setSuccess(false)}
                autoHideDuration={6000}
                key={"toprightEventNotion"}
            >
                <Alert
                onClose={() => setSuccess(false)}
                severity="success"
                variant="filled"
                sx={{ width: '100%' }}
                >
                    The event was successfully created/edited. You should see it in the calendar now.
            </Alert>
            </Snackbar>
        </div>
    );
};

export default EventModal;