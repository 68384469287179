import {Navigate, Route, useLocation} from "react-router-dom";
import React from "react";
import {hasRole, isAuthenticated} from "../util/auth/auth";

interface IPrivateRoute {
    component: any,
    user: any,
    roles?: any[],
    privileges?: any[],
    [rest: string | number | symbol]: unknown;
}

const PrivateRoute : React.FC<IPrivateRoute> = ({component: Component, user, roles, privileges, ...rest}) => {
    const location = useLocation();

    console.log("PRIVATE ROUTE", isAuthenticated(user), Array.isArray(user.roles), roles, roles && hasRole(user, roles));

    // Redirect logic
    if (!isAuthenticated(user)) {
        return <Navigate to="/login" state={{ referrer: location }} replace />;
    }
    if (!Array.isArray(user.roles)) {
        return <Navigate to="/login" state={{ referrer: location }} replace />;
    }
    if (roles && !hasRole(user, roles)) {
        return <Navigate to="/login" state={{ referrer: location }} replace />;
    }

    // If all checks pass, render the component
    return <Component />;
};

interface IPrivateAdminRoute {
    component: any,
    user: any,
    roles?: any[],
    privileges?: any[],
    path: string,

    [rest: string | number | symbol]: unknown;
}


export const PrivateAdminRoute : React.FC<IPrivateAdminRoute> = ({component: Component, user, roles, privileges, path, ...rest}) => {
    //console.log("ADMIN ROUTE")
    const location = useLocation();
    // Redirect logic for admin route
    if (!isAuthenticated(user)) {
        return <Navigate to="/admin/login" state={{ referrer: location }} replace />;
    }
    if (!Array.isArray(user.roles)) {
        return <Navigate to="/admin/login" state={{ referrer: location }} replace />;
    }
    if (roles && !hasRole(user, roles)) {
        return <Navigate to="/admin/login" state={{ referrer: location }} replace />;
    }

    // If all checks pass, render the component
    return <Component />;
};

export default PrivateRoute;