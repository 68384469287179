import axios from "axios";
import {autoLoginInterceptor} from "./relogin";

let relogin_process = false;

export const getReloginProcess = () => {
    return relogin_process;
}

export const setInReloginProcess = (boolean: boolean)  => {
    relogin_process = boolean;
}

const instance = axios.create({
    baseURL: "/"
});

autoLoginInterceptor(instance);

export default instance;