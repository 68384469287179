import React, {useEffect, useRef, useState} from 'react';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import deLocale from '@fullcalendar/core/locales/de';
import {EventClickArg} from "@fullcalendar/core";
import BaseLayout from "../../../layout/BaseLayout";
import instance from "../../../util/api";
import {Calendar, EventType} from "../../../types/EventType";
import { AxiosResponse } from 'axios';
import moment from "moment";
import {Box, Button, LinearProgress} from "@mui/material";
import EventModal, {IEventValues} from "../../../components/EventModal/EventModal";
import {ConfigType} from "../../../types/ConfigType";
import {Moment} from "moment/moment";
import slugify from "slugify";
import {Timer} from "@mui/icons-material";

const Home = () => {
    const [configs, setConfigs] = useState<ConfigType[]>([]);
    const [events, setEvents] = useState<EventType[]>();
    const [event, setCurrentEvent] = useState<IEventValues | undefined>();
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const progressBarRef = useRef<HTMLElement | undefined>(null);
    const calendarRef = useRef<FullCalendar>(null);

    const onClickEvent = (info : EventClickArg) => {
        console.log("EVENT CLICKED", events, info.event.toJSON());

        const foundEventList = events?.filter(event => event.eventUUID === info.event.extendedProps.uuid);

        /*//instance.get("/api/v1/event/" + info.event.id)
        instance.get("/api/v1/event/init")
            .then((resp: AxiosResponse<Calendar>)  => {
                console.log(resp.data)

               /!* if (resp.data.value) {
                    setEvents(resp.data.value)
                }*!/
            })
            .catch(err => {
                console.log(err)
            })*/

        if (foundEventList) {
            const eventList = foundEventList.map((event) => {
                const places = event.locations.map((location) => slugify(location.uniqueId, {
                    lower: true
                }))
                console.log("PLACE", places, event.locations)

                return {
                    id: event.id,
                    startDateTime: '',
                    endDateTime: '',
                    contractor: event.contractor,
                    start: moment(moment.utc(event.start.dateTime).toDate()),
                    startTime: moment(moment.utc(event.start.dateTime).toDate()),
                    end: moment(moment.utc(event.end.dateTime).toDate()),
                    endTime: moment(moment.utc(event.end.dateTime).toDate()),
                    place: places,
                    event_type: event.eventType,
                    category: event.eventCategory,
                    number_persons: event.numberPersons,
                }
            })
                .sort((a, b) => a.start.diff(b.start))

            const foundEvent = {
                details: eventList,
                eventUUID: info.event.extendedProps.uuid,
                mainSubject: foundEventList[0].mainSubject,
                description: foundEventList[0].description || "",
            }

            console.log("FOUND EVENT", foundEvent, foundEventList)


            setCurrentEvent(foundEvent)
            setLoading(false)
        }
    }

    const reloadEvents = () => {
        setLoading(true)
        instance.get("/api/v1/event")
            .then((resp: AxiosResponse<Calendar>)  => {
                console.log(resp.data)

                if (resp.data.value) {
                    setEvents(resp.data.value)
                }
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })

        instance.get("/api/v1/config")
            .then((resp: AxiosResponse<any>)  => {
                console.log(resp.data)

                if (resp.data) {
                    setConfigs(resp.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const onCloseModal = () => {
        setCurrentEvent(undefined)
    }

    useEffect(() => {
        reloadEvents()
    }, []);

    useEffect(() => {
        console.log(calendarRef, progressBarRef)
        if (calendarRef && progressBarRef) {
            // @ts-ignore
            const calendarElement = calendarRef.current?.getApi().view.calendar?.el as HTMLElement | undefined;
            const calendarView = calendarElement?.querySelector(".fc-view-harness") as HTMLElement | undefined;
            console.log(calendarElement, calendarView)

            calendarView?.before(progressBarRef.current as HTMLElement)
        }
    }, [calendarRef, progressBarRef])

    useEffect(() => {
        let timer : NodeJS.Timer | undefined;

        console.log("LOADING", loading)

        if (loading) {
            setProgress(0)
            timer = setInterval(() => {
                setProgress((oldProgress) => {
                    if (oldProgress === 100) {
                        return 0;
                    }
                    const diff = Math.random() * 10;
                    console.log("DIFF", diff)
                    return Math.min(oldProgress + diff, 100);
                });
            }, 500);
        } else {
            if (timer) {
                setProgress(100)
                clearInterval(timer);
            }
        }

        return () => {
            if (timer) {
                setProgress(100)
                clearInterval(timer);
            }
        };
    }, [loading]);


    const eventList = events?.map((event) => {
        return {
            id: event.id,
            uuid: event.eventUUID,
            allDay: event.isAllDay,
            title: event.subject,
            start: moment.utc(event.start.dateTime).toDate(),
            end: moment.utc(event.end.dateTime).toDate(),
        }
    })

    console.log(eventList)

    return (
        <BaseLayout>
            <Box>
                <h1>Dashboard</h1>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: 2
                }}
            >
                <EventModal callback={reloadEvents} event={event} setCloseCb={onCloseModal} configs={configs} />
            </Box>
            <div className="calendar">
                <LinearProgress id="progressHolder" ref={progressBarRef} variant="determinate" value={progress} />

                <FullCalendar
                    aspectRatio={1.6}
                    locale={deLocale}
                    ref={calendarRef}
                    themeSystem='bootstrap'
                    initialView="timeGridWeek"
                    eventClick={onClickEvent}
                    displayEventTime={false}
                    datesSet={(data) => {
                        console.log("VIEW", data)
                        setLoading(true);
                        instance.get("/api/v1/event", {
                            params: {
                                startDateTime: data.startStr,
                                endDateTime: data.endStr
                            }
                        })
                            .then((resp: AxiosResponse<Calendar>)  => {
                                console.log(resp.data)

                                if (resp.data.value) {
                                    setEvents(resp.data.value)
                                }

                                setLoading(false)
                            })
                            .catch(err => {
                                setLoading(false)
                                console.log(err)

                        })
                    }}
                    headerToolbar={{
                        left: 'prev title next',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay'
                    }}
                    titleFormat={{year: 'numeric', month: 'long'}}
                    //eventContent={renderEventContent} // custom render function
                    events={eventList}
                    //datesSet={onDateSet}
                    dayHeaderFormat={{
                        weekday: 'short',
                        month: 'numeric',
                        day: 'numeric',
                        omitCommas: true }}
                    plugins={[dayGridPlugin, timeGridPlugin]}/>

                {/*{event ?
                type !== "USER" ?
                    <EventModal modal={modal} toggle={toggle} event={event}/>
                    : <EventModalUser modal={modal} toggle={toggle} event={event}/>
                : <div></div>}*/}
            </div>
        </BaseLayout>
    );
};

export default Home;