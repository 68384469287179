import React, {useEffect} from 'react';
import {Alert, Box, Button, Grid, TextField} from "@mui/material";
import BaseLayout from '../../../layout/BaseLayout';
import {FieldArray, FormikProvider, useFormik} from "formik";
import slugify from "slugify";
import instance from "../../../util/api";
import CheckIcon from '@mui/icons-material/Check';

const Config = () => {
    const [success, setSuccess] = React.useState(false)
    const [error, setError] = React.useState<boolean | string>(false)

    const formik = useFormik({
        initialValues: {
            places: [{
                label: "",
                value: ""
            }],
            eventTypes: [{
                label: "",
                value: ""
            }],
            categories: [{
                label: "",
                value: ""
            }],
        },
        onSubmit: values => {
            values.places = values.places.map((place) => ({
                ...place,
                value: slugify(place.label, {
                    lower: true
                })
            }))
            values.eventTypes = values.eventTypes.map((eventType) => ({
                ...eventType,
                value: slugify(eventType.label, {
                    lower: true
                })
            }))
            values.categories = values.categories.map((category) => ({
                ...category,
                value: slugify(category.label, {
                    lower: true
                })
            }))

            instance.post("/api/v1/config", values)
                .then(resp => {
                    console.log(resp)
                    setSuccess(true)
                })
                .catch(err => {
                    setError(err.data.message)
                    console.log(err)
                })

            console.log(values);
        },
    });

    useEffect(() => {
        instance.get("/api/v1/config")
            .then(resp => {
                const data = resp.data.reduce((acc: any, item: any) => {
                    acc[item.key] = item.value
                    return acc
                }, {})

                console.log(data, resp.data)
                formik.setValues(data)
            })
            .catch(err => {
                console.log(err)
            })
    }, []);

    return (
        <BaseLayout>
            <Box>
                <h1>Options</h1>
            </Box>

            <Box>
                {success && <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                    Successfully saved
                </Alert>}

                {error && <Alert severity="error">{error}</Alert>}
            </Box>

            <Box>
                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit}>
                        {["places", "eventTypes", "categories"].map((field, index) => (
                            <FieldArray name={field}>
                                {({push, remove}) => (
                                    <div>
                                        <h2>{field.charAt(0).toUpperCase() + field.slice(1)}</h2>
                                        {formik.values[field as keyof typeof formik.values].map((place, index) => (
                                            <Grid container key={index}>
                                                <Grid item xs={10}>
                                                    <TextField
                                                        margin="normal"
                                                        sx={{width: '50%'}}
                                                        required
                                                        name={`${field}.${index}.label`}
                                                        onChange={formik.handleChange}
                                                        value={place.label}
                                                        label={field.charAt(0).toUpperCase() + field.slice(1)}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Button
                                                        variant="contained"
                                                        color={"error"}
                                                        sx={{mt: 3, mb: 2, float: 'right'}}
                                                        onClick={() => remove(index)}
                                                    >
                                                        X
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        ))}
                                        <Button
                                            variant="contained"
                                            color={"secondary"}
                                            sx={{ mt: 3, mb: 2, float: 'right'}}
                                            onClick={() => push({
                                                label: "",
                                                value: ""
                                            })}
                                        >
                                            Add Place
                                        </Button>
                                    </div>
                                )}
                            </FieldArray>
                            ))}

                        <Button
                            type={"submit"}
                            variant="contained"
                            color={"success"}
                            sx={{ mt: 3, mb: 2 }}
                        >Save</Button>
                    </form>
                </FormikProvider>
            </Box>
        </BaseLayout>
);
};

export default Config;