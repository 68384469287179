import React from 'react';
import './App.css';
import NavigationLinks from "../routes/NavigationLinks";
import {getCookie} from "../util/api/cookies";
import {useAppDispatch} from "../state/stateHooks";
import {userLoginSuccessAction} from "../state/action/user/userAction";
import instance from "../util/api";
import "../assets/scss/style.scss"

const cookie = getCookie("authenticated")

// if cookie is true => set userLoginSuccessAction

function App() {
    const dispatch = useAppDispatch();

    // TODO - check if user already logged in - dont get this info everytime

    if (cookie){
        instance.get("/api/v1/user")
            .then(resp => {
                console.log("/api/v1/user", resp.data)
                dispatch(userLoginSuccessAction(
                    resp.data,
                    "",
                    ""
                ))
            })
            .catch(err => {
                console.log(err)
            })

    }

  return (
    <div className="App">
      <NavigationLinks />
    </div>
  );
}

export default App;
