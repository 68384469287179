import {createReducer} from "@reduxjs/toolkit";
import {
    userActivationAction,
    userLoginFailureAction,
    userLoginInitAction,
    userLoginSuccessAction, userLogoutAction,
    userRefreshTokenAction
} from "../../action/user/userAction";


const initialState = {
    _id: -1,
    token: null as string | null,
    refreshToken: null as string | null,
    isAuthenticated: false,
    roles: [],
    privileges: [],
    firstname: "",
    lastname: "",
    email: "",
    username: "",
    socketId: "",
    error: {error: false, message: ""}
};

const userData = createReducer(initialState, (builder => {
    builder
        .addCase(userLoginInitAction, (state, action) => {
            return {
                ...initialState
            }
        })
        .addCase(userLoginSuccessAction, (state, action) => {
            const privileges : string[] = [];

            console.log("LOGIN_SUCCESSFULL_REDUCER", action);

            /*if (account.roles){
                account.roles.forEach((role: IAccountRole) => privileges.push(...role.privileges.map(privilege => privilege.handle)));
            }*/
            const {user, access_token, refresh_token} = action.payload;

            return {
                ...action.payload.user,
                token: access_token,
                refreshToken: refresh_token,
                firstname: user.firstname,
                lastname: user.lastname,
                email: user.email,
                username: user.username,
                isAuthenticated: true,
                //thumbnail: user.thumbnail,
                //roles: user.roles.map((role: any) => role.role),
                roles: ["user"],
                //is_active: user.is_active,
                error: {error: false, message: ""}
            };
        })
        .addCase(userRefreshTokenAction, (state, action) => {

            console.log("LOGIN_REFRESH_TOKEN", action);
            const {access_token, refresh_token} = action.payload;

            return {
                ...state,
                token: access_token,
                refreshToken: refresh_token,
                isAuthenticated: true,
                error: {error: false, message: ""}
            };
        })
        .addCase(userLoginFailureAction, (state, action) => {
            return {
                ...state,
                error: {error: true, message: action.payload}
            }
        })
        .addCase(userLogoutAction, (state, action) => {
            return {
                ...initialState
            }
        })
        .addCase(userActivationAction, (state, action) => {
            if (action.payload.type === "contract") {
                return {
                    ...state,
                    vertrag_unterschrieben: action.payload.success
                }
            } else if (action.payload.type === "activation") {
                return {
                    ...state,
                    is_active: action.payload.success
                }
            } else if (action.payload.type === "activation_request") {
                return {
                    ...state,
                    activation_request_sent: action.payload.success
                }
            }

            return state;
        })
    })
);


export default userData;