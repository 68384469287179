import React, {useEffect} from 'react';
import {useFormik} from "formik";
import {Box, Button, Container, CssBaseline, TextField} from "@mui/material";
import instance from "../../../util/api";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useAppSelector} from "../../../state/stateHooks";
import {HOME} from "../../../routes/routes";

const Login = () => {
    const user = useAppSelector(state => state.user);
    const navigate = useNavigate();

    useEffect(() => {
        if (user.isAuthenticated) {
            navigate(HOME)
        }
    }, [user]);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        onSubmit: values => {
            alert(JSON.stringify(values, null, 2));
        },
    });


    return (
        <Container component="main">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <h1>Event Manager</h1>
                <form onSubmit={formik.handleSubmit}>
                    {/*<TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </Button>*/}

                    <Button
                        href="/auth/microsoft"
                        fullWidth
                        variant="contained"
                        color={"primary"}
                        sx={{ mt: 3, mb: 2 }}
                    >Login with Microsoft</Button>
                </form>
            </Box>
        </Container>
    );
};

export default Login;