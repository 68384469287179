

export const loadCookies = () => {
    const cookie = document.cookie;
    const list =  cookie.split(";");
    return list.map(cookie => cookie.split("="))
        .map(([key, value]) => ({[key]: value}))
        .reduce((acc, cookie) => ({...acc, ...cookie}), {});
}
export const getCookie = (name: string) => {
    const cookie = loadCookies()[name];
    return cookie ? cookie : '';
}