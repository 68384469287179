import React, {ReactNode} from 'react';
import {
    AppBar,
    Box,
    Button,
    Container,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton, ListItemIcon, ListItemText,
    Toolbar,
    Typography
} from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import {Link, NavLink} from "react-router-dom";
import {CONFIG, HOME} from "../routes/routes";

interface IBaseLayout {
    className?: any,
    header?: ReactNode,
    sidebar?: ReactNode
    children?: ReactNode
}

const drawerWidth = 240;

const BaseLayout : React.FC<IBaseLayout> = (props) => {
    return (
        <div className="BaseLayout">
            <header>
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                        <Toolbar>
                            {/*<IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                            >
                                <MenuIcon />
                            </IconButton>*/}
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                Event Manager
                            </Typography>
                            <Button
                                href="/auth/logout"
                                color="inherit">Logout</Button>
                        </Toolbar>
                    </AppBar>
                </Box>
            </header>

            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <List>
                        <ListItem key={"Dashboard"} disablePadding>
                            <ListItemButton
                                component={NavLink}
                                to={HOME}>
                                <ListItemIcon>
                                    <HomeIcon />
                                </ListItemIcon>
                                <ListItemText primary={"Dashboard"} />
                            </ListItemButton>
                        </ListItem>
                        <ListItem key={"Options"} disablePadding>
                            <ListItemButton component={NavLink} to={CONFIG}>
                                <ListItemIcon>
                                    <SettingsIcon />
                                </ListItemIcon>
                                <ListItemText primary={"Options"} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>

            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Toolbar />
                <Container maxWidth={"lg"}
                           sx={{
                               marginTop: 4
                           }}
                >
                    {props.children}
                </Container>
            </Box>
        </div>
    );
};

export default BaseLayout;