import {RootState} from "../store";
import {connect, ConnectedProps} from "react-redux";
import {Navigate, Route, Routes} from "react-router-dom";
import * as ROUTES from '../routes/routes';
import React from "react";
import Home from "../pages/admin/home/Home";
import PrivateRoute from "./PrivateRoute";
import {useAppSelector} from "../state/stateHooks";
import Login from "../pages/admin/login/Login";
import Config from "../pages/admin/config/Config";

interface INavigationLinks {

}

const NavigationLinks : React.FC<INavigationLinks> = () => {
    const user = useAppSelector(state => state.user);

    return (
        <div>
            <Routes>
                <Route path={ROUTES.LOGIN} element={<Login />} />

                <Route path={ROUTES.HOME} element={<PrivateRoute component={Home} user={user} roles={['user']} />} />
                <Route path={ROUTES.CONFIG} element={<PrivateRoute component={Config} user={user} roles={['user']} />} />
            </Routes>
        </div>
    )
}


export default NavigationLinks;