import {configureStore, Middleware} from "@reduxjs/toolkit";
import rootReducer from './state/reducer';
import {thunk} from "redux-thunk";
import * as localforage from "localforage";
import { persistStore, persistReducer } from 'redux-persist'
const multiActionMiddleware: Middleware<{}, any> = (store) => (next) => (action) => {
    if (!Array.isArray(action)) return next(action);
    return action.map(a => store.dispatch(a));
};

const persistConfig = {
    key: 'root',
    storage: localforage,
    debug: true
};

const pReduce = persistReducer<ReturnType<typeof rootReducer>>(persistConfig, rootReducer);


const currentStore = configureStore({
    reducer: pReduce,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['persist/PERSIST']
            }
        }).concat(thunk, multiActionMiddleware),
    devTools: process.env.NODE_ENV !== "production"
})


export const store = currentStore;
export const persistor = persistStore(store);
export type PersistRootState = ReturnType<typeof pReduce>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof currentStore.dispatch

export type AppStore = typeof store
